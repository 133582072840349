<template>
  <div class="overview-wrapper">
    <div class="side-wrapper">
      <ul class="list-group list-group-flush">
        <a
          v-for="(col, i) in searchOptions"
          :key="i"
          href="#"
          class="list-group-item list-group-item-action flex-column align-items-start side-item"
          @click="sideColClicked(i)"
        >
          {{ col.name }}
        </a>
      </ul>
    </div>
    <div class="content-wrapper">
      <b-breadcrumb :items="crumb_links"></b-breadcrumb>
      <h2>{{ overviewTitle }}</h2>
      <p>{{ description }}</p>
      <div class="options-panel">
        <div
          v-for="(option, i) in searchOptions"
          :key="i"
          class="search-option"
        >
          <h3 class="pg-link" @click="sideColClicked(i)">{{ option.name }}</h3>
          <p>{{ option.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortalsOverview",
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Portals",
          active: true,
        },
      ],
      overviewTitle: "Portals Overview",
      description:
        "Searching for something specific, or just looking to see what resources are available for Arabidopsis researchers? Start at one of these portals which will introduce you to relevant topics and resources.",
      searchOptions: [
        {
          name: "Community Resources",
          description:
            "Community Resources Portal is a curated set of bioinformatics and data resources that have been compiled by members of the International Arabidopsis Informatics Consortium (IAIC) and curators at TAIR. The portal can be searched or browsed by data type or keywords.",
          route: "https://conf.phoenixbioinformatics.org/display/COM/Resources",
        },
        {
          name: "Clones/DNA Resources",
          description:
            "Information about Arabidopsis DNA stocks, and links to stock centers from which they are distributed.",
          route:
            "https://conf.phoenixbioinformatics.org/pages/viewpage.action?pageId=8160428",
        },
        {
          name: "Education and Outreach",
          description:
            "Educational information about Arabidopsis, plant biology, genetics, genomics, methods in Arabidopsis research (protocols). Tools and tips for scientists, students and teachers.",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Education+and+Outreach+Resources",
        },
        {
          name: "Gene Expression Resources",
          description:
            "Information on Arabidopsis high throughput expression data, such as microarrays, GFP-fusion proteins, and Massively Parallel Signature Sequencing technologies.",
          route:
            "https://conf.phoenixbioinformatics.org/pages/viewpage.action?pageId=8160054",
        },
        {
          name: "Genome Annotation",
          description:
            "Information on the primary structure of Arabidopsis thaliana genes, including intron-exon structure, intron lengths, alternative splicing and untranslated regions (UTRs), as well as on the function of the gene products.",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Genome+Annotation",
        },
        {
          name: "MASC/Functional Genomics",
          description:
            "Information about the Multinational Arabidopsis Steering Committee (MASC), its Subcommittees, and Arabidopsis functional genomics projects and resources worldwide.",
          route: "https://arabidopsisresearch.org/",
        },
        {
          name: "Metabolomics Resources",
          description:
            "Information about metabolic pathway and compound databases, Arabidopsis metabolite profiling data sets, tools, experimental reporting standards, and other metabolomics resources.",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Metabolomics+Resources",
        },
        {
          name: "Mutant and Mapping Resources",
          description:
            "Information on forward and reverse genetics resources for Arabidopsis.",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Mutant+and+Mapping+Resources",
        },
        {
          name: "Nomenclature",
          description:
            "Presents links to guidelines for gene nomenclature for Arabidopsis and other organisms.",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Nomenclature",
        },
        {
          name: "Proteome Resources",
          description:
            "Information on Arabidopsis proteins, including sequence, structure, motifs, and localization data.",
          route:
            "https://conf.phoenixbioinformatics.org/display/COM/Proteomics+Resources",
        },
      ],
    };
  },
  mounted() {
    document.title = "Portals Overview";
  },
  methods: {
    sideColClicked(index) {
      window.open(this.searchOptions[index].route, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.content-wrapper {
  width: 90%;
  padding-left: 20px;

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
  }
  p {
    margin-bottom: 20px;
    text-align: left;
  }

  .pg-link {
    cursor: pointer;
  }
  .pg-link:hover {
    background: #feffbb;
  }
}
</style>
